<template>
<div>
    <div class="row justify-content-center py-16 px-8 py-md-16 px-md-0 bgi-size-cover bgi-position-center" style="background: #2a84a6 url('')">
    <div class="col-md-6 text-white">
      <div class="d-flex ">
        <div class="d-flex flex-column px-0 order-2 order-md-1">
            <h2 class="display-3 font-weight-boldest order-1" v-html="$t('COOKIE.TITOLO')"></h2>
            <h3 class="mb-10 order-2" v-html="$t('COOKIE.SOTTOTITOLO')"></h3>
        </div>
      </div>
    </div>
  </div>
  <div v-if="!getCookiePolicy" class="row justify-content-center py-8 px-8 py-md-20 px-md-0 bg-default">
    <div class="col-lg-8 col-xl-6">
      <div class="row align-items-center mt-5">
        <h4>

        </h4>
        <h3 class="subtitle" v-html="$t('COOKIE.COSASONO')"></h3>
         <p class="lead" v-html="$t('COOKIE.COSASONODESCR1')"></p>
        <h3 class="subtitle" v-html="$t('COOKIE.UTILIZZATI')"></h3>
         <p class="lead" v-html="$t('COOKIE.UTILIZZATIDESCR1')">
            </p>

        <h3 class="subtitle" v-html="$t('COOKIE.ANONIMI')"></h3>
         <p class="lead" v-html="$t('COOKIE.ANONIMIDESCR1')">
        </p>
        <h3 class="subtitle" v-html="$t('COOKIE.DATIRACCOLTI')"></h3>
         <p class="lead" v-html="$t('COOKIE.DATIRACCOLTIDESCR1')">
        </p>
        <h3 class="subtitle" v-html="$t('COOKIE.CONTENUTIESTERNI')"></h3>
         <p class="lead" v-html="$t('COOKIE.CONTENUTIESTERNIDESCR1')">
        </p>

        <h3 class="subtitle" v-html="$t('COOKIE.COLLEGAMENTI')"></h3>
         <p class="lead" v-html="$t('COOKIE.COLLEGAMENTIDESCR1')">
        </p>

      </div>
    </div>
  </div>
  <div v-else class="row justify-content-center py-8 px-8 py-md-20 px-md-0 bg-default">
    <div class="col-lg-8 col-xl-6">
      <div class="row align-items-center mt-5" v-html="cookiePolicy">
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "cookie",
  data() {
    return {
      cookiePolicy: "",
    };
  },
  computed: {
    getCookiePolicy() {
      return this.cookiePolicy != '';
    },
    ...mapGetters([
      "getOrganization"
    ]),
  },
  mounted() {
    this.cookiePolicy = this.getOrganization?.data?.cookiePolicy ?? '';
  },
};
</script>

<style scoped>

</style>
